<template>
  <div>
    <div class="radius_bg">
      <el-date-picker
          v-model="formData.date"
          placeholder="月份选择"
          type="month"
          format="yyyy年MM月"
          value-format="yyyy-MM"
          @change="getList">
      </el-date-picker>
      <el-button style="margin-left: 10px" @click='getList' type='primary' icon='el-icon-search'>查询
      </el-button>
      <el-button v-if="userPermissions.indexOf('live-product-view-export') !== -1" :loading="downloadLoading"
                 type="primary" @click="exportExcel" icon="el-icon-download">导出列表
      </el-button>
      <br/><br/>
    </div>
    <div class="default-table radius_bg" style="padding-bottom: 20px">
      <el-table :data="dataList" ref="multipleTable" row-key="id"
                v-if="userPermissions.indexOf('live-product-view-list') !== -1" id="kolAnalysisTable"
                highlight-current-row :summary-method="getSummaries"
                @cell-click="showInput" :cell-class-name='addCellClass'
                show-summary v-loading="isLoading" border>
        <el-table-column align="center" label="序号" type="index" width="60"></el-table-column>
        <el-table-column align="center" prop="leader_name" show-overflow-tooltip label="姓名"
                         width="120"></el-table-column>
        <el-table-column align="center" prop="created_at" show-overflow-tooltip label="时间"
                         width="180">
          <template slot-scope="{row}">{{row.created_at.slice(0, 7) }}</template>
        </el-table-column>
        <el-table-column align="center" prop="commit_count" label="提交商品" width="120" sortable />
        <el-table-column align="center" prop="check_count" label="通过商品" width="120" sortable />
        <el-table-column align="center" prop="brand_count" label="品牌数" width="120" sortable />
        <el-table-column align="center" prop="new_brand_count" label="品牌拓新数" width="120" sortable />
      </el-table>
    </div>
    <el-dialog :title='brandFrom.info.title' :visible.sync='brandFrom.open' center width='50%' append-to-body :close-on-click-modal='false'>
      <div style="width: 95%;margin: 20px auto">
        <el-table :data="brandFrom.list" border style="width: 100%">
          <el-table-column type="index" width="50" label='序号' header-align="center" align="center" />
          <el-table-column prop="name" label="品牌名称" min-width="200" header-align="center" align="center" />
          <el-table-column prop="contact_user" label="联系人" min-width="60" header-align="center" align="right" />
          <el-table-column prop="contact_info" label="联系电话" min-width="120" header-align="center" align="center" />
          <el-table-column prop="created_at" label="录入时间" min-width="120" header-align="center" align="center" />
        </el-table>
      </div>
      <div>
        <el-row>
          <el-col :span="24">
            <div>
              <m-pagination :limit.sync='brandFrom.pageData.page_size'
                            :page.sync='brandFrom.pageData.current_page'
                            :total.sync='brandFrom.pageData.total'
                            :hidden='brandFrom.pageData.total===0'
                            @pagination='getNewBrandList'></m-pagination>
            </div>
          </el-col>
        </el-row>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ExportBtn from '@/components/export/ExportBtn'
import * as XLSX from 'xlsx-js-style'

export default {
  name: 'investment',
  data() {
    return {
      formData: { date: '' },
      dataList: [],
      isLoading: false,
      downloadLoading: false,
      today: '',
      brandFrom: {
        info: { title: '', suffixTitle: '品牌拓新列表' },
        open: false,
        queryData: { leader_id: null, Ymd: null },
        pageData: { current_page: 1, page_size: 10, total: 0 },
        orderBy: { created_at: 'desc' },
        list: []
      }
    }
  },

  components: {
    ExportBtn
  },
  computed: {
    ...mapGetters(['userPermissions'])
  },
  methods: {
    getSummaries(param) {
      const { columns, data } = param
      const sums = []
      columns.forEach((column, index) => {
        if (index < 1) {
          return
        }
        if (index === 2) {
          sums[index] = '合计:'
          return
        }
        if (['commit_count', 'check_count', 'brand_count', 'new_brand_count'].indexOf(column.property) > -1) {
          const values = data.map(item => Number(item[column.property]))
          if (!values.every(value => isNaN(value))) {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr)
              if (!isNaN(value)) {
                return prev * 1 + curr * 1
              } else {
                return prev
              }
            }, 0)
            sums[index] += ''
          } else {
            sums[index] = 'N/A'
          }

        }
      })

      return sums
    },
    async getList() {
      this.isLoading = true
      this.dataList = []
      //
      let selectInfo = {
        date: this.formData.date + '-01'
      }
      if (!this.formData.date) {
        selectInfo = {
          date: this.today + '-01'
        }
      }

      this.dataList = await this.$api.investmentGetList(selectInfo)

      this.isLoading = false

    },
    showInput(row, column) {
      if (column.property === 'new_brand_count') {
        if (row.leader_id && this.formData.date && row.new_brand_count > 0) {
          const Ymd = this.formData.date + '-01'
          this.brandFrom.queryData = { leader_id: row.leader_id, Ymd: Ymd}
          let YmdDate = new Date(Ymd)
          const Y = YmdDate.getFullYear()
          const Month = YmdDate.getMonth() + 1
          this.brandFrom.info.title = Y + '年' + Month + '月【' + row.leader_name + '】' + this.brandFrom.info.suffixTitle
          this.brandFrom.open = true
          this.getNewBrandList()
        } else {
          this.brandFrom.info.title = ''
        }
      }
    },
    addCellClass({ column }) {
      if (column && column.property === 'new_brand_count') {
        return 'cell-click'
      }
    },
    defaultTime() {
      const end = new Date()
      //年份选择：默认当月
      this.formData.date = end.getFullYear() + '-' + (Number(end.getMonth() + 1) < 10 ? '0' + Number(end.getMonth() + 1) : end.getMonth() + 1)
      this.today = end.getFullYear() + '-' + (Number(end.getMonth() + 1) < 10 ? '0' + Number(end.getMonth() + 1) : end.getMonth() + 1)
    },
    // async downLoad() {
    //   try {
    //     let isFileSaverSupported = !!new Blob
    //     if (isFileSaverSupported) {
    //       // let searchCondition = this.handleSearchCondition()
    //       let selectInfo = {
    //         date: this.formData.date + '-01',
    //         is_export: true
    //       }
    //       let response = await this.$api.investmentGetList(selectInfo)
    //       let name = `招商情况一览`
    //       this.$refs.refExportLink.exportSaveXlsx(response, name)
    //     }
    //   } catch (e) {
    //     this.$message.warning('浏览器不支持导出文件')
    //   }
    // },
    exportExcel: function() {
      this.downloadLoading = true
      const xlsxParam = { raw: false } //转换成excel时，使用原始的格式
      /* 生成工作表 */
      const workbook = XLSX.utils.book_new()
      /* 从表生成工作簿对象 */

      let worksheet = XLSX.utils.table_to_sheet(
          document.querySelector('#kolAnalysisTable'),
          xlsxParam
      )

      /* 设置工作簿样式 */
      worksheet = this.worksheetStyle(worksheet)

      /* 工作簿导出 */
      XLSX.utils.book_append_sheet(workbook, worksheet, '单个红人数据分析')
      XLSX.writeFile(workbook, `${this.formData.date}月招商情况一览.xlsx`)

      this.downloadLoading = false
    },
    /* 设置工作簿样式 */
    worksheetStyle: function(worksheet) {
      // 表样式设置
      const colsLength = 14
      let cols = [] // 设置每列的宽度
      // wpx 字段表示以像素为单位，wch 字段表示以字符为单位
      for (let i = 0; i <= colsLength; i++) {
        let col = {}
        if (i == 0) {
          col.wch = 30
        } else {
          col.wch = 18
        }
        cols.push(col)
      }
      worksheet['!cols'] = cols // 设置列宽信息到工作表
      //以下是样式设置，样式设置放在组织完数据之后，xlsx-js-style的核心API就是SheetJS的
      Object.keys(worksheet).forEach(key => {

        // 非!开头的属性都是单元格
        if (!key.startsWith('!')) {
          if (key.indexOf('A') != -1 || ['A1', 'B1', 'C1', 'D1', 'E1'].includes(key)) {
            if (key.indexOf('A') != -1) {
              if (key === 'A1') {
                worksheet[key].s = {
                  font: {
                    sz: '14',
                    name: 'Microsoft YaHei',
                    color: { rgb: 'FFFFFF' }

                  },
                  alignment: {
                    horizontal: 'center',
                    vertical: 'center',
                    wrapText: true
                  },
                  border: {
                    // top: { style: 'thin' },
                    // right: { style: 'thin' },
                    // bottom: { style: 'thin' },
                    // left: { style: 'thin' }
                  },
                  fill: {
                    fgColor: { rgb: '62d2ae' }
                  }
                }
              } else
                worksheet[key].s = {
                  font: {
                    sz: '12',
                    name: 'Microsoft YaHei'
                  },
                  alignment: {
                    horizontal: 'center',
                    vertical: 'center',
                    wrapText: true
                  },
                  border: {
                    // top: { style: 'thin' },
                    // right: { style: 'thin' },
                    // bottom: { style: 'thin' },
                    // left: { style: 'thin' }
                  }
                }
            } else {
              worksheet[key].s = {
                font: {
                  sz: '12',
                  color: { rgb: 'FFFFFF' },
                  name: 'Microsoft YaHei'
                },
                alignment: {
                  horizontal: 'center',
                  vertical: 'center',
                  wrapText: true
                },
                border: {
                  // top: { style: 'thin' },
                  // right: { style: 'thin' },
                  // bottom: { style: 'thin' },
                  // left: { style: 'thin' }
                },
                fill: {
                  fgColor: { rgb: '62d2ae' }
                }
              }
            }
            worksheet[key].t = 's'
          } else {
            worksheet[key].s = {
              font: {
                sz: '14'
              },
              alignment: {
                horizontal: 'right',
                vertical: 'right',
                wrapText: true
              },
              border: {
                // top: { style: 'thin' },
                // right: { style: 'thin' },
                // bottom: { style: 'thin' },
                // left: { style: 'thin' }
              }
            }
            worksheet[key].t = 'n'
            worksheet[key].s.numFmt = 2
          }
        }

      })

      return worksheet
    },
    // 库位分布查询获取 分页 +排序
    handleDialogSearchCondition(from) {
      let condition = from.queryData
      // 分页 +排序
      Object.assign(condition, from.pageData, { orderBy: from.orderBy })
      return condition
    },
    async getNewBrandList() {
      let { list, pages } = await this.$api.getNewBrandList(this.handleDialogSearchCondition(this.brandFrom))
      this.brandFrom.list = list
      this.brandFrom.pageData = pages
    }

  },
  mounted() {
    this.defaultTime()
    this.getList()
  }
}
</script>

<style lang="scss" scoped>
@import "@/pages/lb/style/main.scss";

</style>

<style>
.cell-click {
  color: #1890FF;
  cursor:pointer;
}
</style>